import React from 'react';
import './styles.css';

const Portfolio = () => {
  return (
    <div className="portfolio">
      <header className="portfolio-header">
        <p>
          Gayathri Balasubramani's Portfolio - WIP
        </p>
      </header>
    </div>
  );
}

export default Portfolio;
